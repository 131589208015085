import { env } from "@/env.mjs"
import { MyProfile } from "@/models/my-profile"

export const myProfile: MyProfile = {
  name: "Simon Betton",
  jobTitle: "Technical Lead / Staff Software Engineer",
  gender: "male",
  nationality: "New Zealand",
  //
  image: {
    src: "simon-betton-profile-picture.jpg",
  },
  //
  birthPlace: "Auckland, NZ",
  birthDate: new Date("1988-05-20"),
  //
  alumniOf: "Rosmini College",
  //
  cityOrState: "Auckland",
  countryCode: "NZ",
  //
  phoneNumber: "+64 27 252 5598",
  emailAddress: "me@simonbetton.com",
  //
  twitterHandle: "@simonbetton",
  twitterUrl: "https://twitter.com/simonbetton",
  linkedInUrl: "https://www.linkedin.com/in/simonbetton",
  //
  dateCareerBegan: new Date("2007-01-07"),
  //
  resumeUrl: new URL(
    "/files/Simon-Betton-Resume.pdf",
    env.NEXT_PUBLIC_PRIMARY_FQDN_DOMAIN
  ).toString(),
}

MyProfile.parse(myProfile)
