import omit from "lodash/omit"
import type { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from "react"

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: ReactNode
  as?: "a"
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode
  as?: "button"
}

type Props = AnchorProps | ButtonProps

export function StyledLink(props: Props) {
  if (isButtonProps(props)) {
    return (
      <button
        {...omit(props, ["as", "children", "className"])}
        className={`transition-colors focus:outline-none font-semibold underline decoration-neutral-300 dark:decoration-neutral-600 hover:decoration-yellow-300 dark:hover:decoration-yellow-300 cursor-pointer dark:text-white text-neutral-900 ${props.className}`}
      >
        {props.children}
      </button>
    )
  }

  if (isAnchorProps(props)) {
    return (
      <a
        {...omit(props, ["as", "children", "className"])}
        className={`transition-colors focus:outline-none font-semibold underline decoration-neutral-300 dark:decoration-neutral-600 hover:decoration-yellow-300 dark:hover:decoration-yellow-300 cursor-pointer dark:text-white text-neutral-900 ${props.className}`}
      >
        {props.children}
      </a>
    )
  }

  return null
}

function isAnchorProps(p: Props): p is AnchorProps {
  return (p as AnchorProps).as === "a" || (p as AnchorProps).as === undefined
}

function isButtonProps(p: Props): p is ButtonProps {
  return (p as ButtonProps).as === "button"
}
