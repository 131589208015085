import { NextJSImage } from "@/models/nextjs-image"
import { z } from "zod"

export const MyProfile = z
  .object({
    name: z.string().min(1), // e.g. John Doe (used as the business name also)
    jobTitle: z.string().min(1),
    gender: z.enum(["male", "female"]).optional(),
    nationality: z.string().min(1).optional(), // e.g. New Zealand
    //
    image: NextJSImage,
    //
    birthPlace: z.string().min(1).optional(),
    birthDate: z.date().optional(),
    //
    alumniOf: z.string().optional(),
    //
    cityOrState: z.string().min(1).optional(),
    countryCode: z.string().min(1).optional(),
    //
    phoneNumber: z.string().min(1).optional(),
    emailAddress: z.string().min(1).email().optional(),
    //
    twitterHandle: z.string().min(1).startsWith("@").optional(), // e.g. @john
    twitterUrl: z.string().min(1).url().startsWith("https://twitter.com/").optional(), // e.g. https://twitter.com/john
    linkedInUrl: z.string().min(1).url().startsWith("https://www.linkedin.com/").optional(), // e.g. https://www.linkedin.com/in/john
    //
    dateCareerBegan: z.date(), // Required due to usage in template literals. Soz.
    //
    resumeUrl: z.string().min(1).optional(),
  })
  .strict()
export type MyProfile = z.infer<typeof MyProfile>
