import { z } from "zod"

// This is based on the Next.js Image component `StaticImageData` type.
// https://nextjs.org/docs/pages/api-reference/components/image#staticimagedata
export const NextJSImage = z.object({
  src: z.string().min(1),
  height: z.number().optional(),
  width: z.number().optional(),
  // We don't care about the other properties at
  // this point. We just need to validate we have a src property.
  //
  // Zod does not assert anything about unspecified properties,
  // therefore have intentionally not used `z.object({ ... }).strict()`
})
export type NextJSImage = z.infer<typeof NextJSImage>
